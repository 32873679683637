import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { useSelector, useDispatch } from 'react-redux';
import { remove } from '../../redux/userSlice';
import logo from '../../images/logo.png';

import { getFormattedDate } from '../../lib/helper';

import classes from './Header.module.css';
const burger = classes.burger;
const burgerActive = `${classes.burger} ${classes.active}`;

const Header = () => {

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState({});
  const [burgerClass, setBurgerClass] = useState(classes.burger);

  useEffect(() => {
    setLoggedInUser(user);
  }, [user]);

  // const currentDate = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
  const currentDate = getFormattedDate(new Date());

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('idleTime');
    dispatch(remove());
    navigate('/');
  };

  const handleLogin = () => {
    navigate('/');
  };

  const handleMenuClick = () => {
    if (
      document.body.classList?.length &&
      document.body.classList.contains("show-menu")
    ) {
      document.body.classList.remove("show-menu");
      setBurgerClass(burger);
    } else {
      document.body.classList.add("show-menu");
      setBurgerClass(burgerActive);
    }
  };

  const checkIdleTime = () => {
    const idleTime = window.localStorage.getItem('idleTime');
    let currentTime = new Date().getTime();

    const isIdleTime = Math.floor((currentTime - (idleTime || currentTime)) / 1000);    
    if(isIdleTime > 150 ) {
      handleLogout();
      window.localStorage.removeItem('idleTime');
    } else {
      window.localStorage.setItem('idleTime', currentTime);
    }

  }
  
  useEffect(() => { 
    checkIdleTime();  
    document.body.addEventListener('keypress', (e) => {
      checkIdleTime();
    });

    document.body.addEventListener('click', (e) => {     
      checkIdleTime();
    });

    document.body.addEventListener('mousemove', (e) => {     
      checkIdleTime();
    });

    window.addEventListener('scroll', (e) => {       
      checkIdleTime();
    });

  }, []);

  return (
    <header className={classes.header}>
      <div className={classes.head}>
        <div className={classes.logoWrapper}>
        {/* {user && user.type ? (
          <div className={burgerClass} onClick={handleMenuClick}>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
          </div>
        ) : null} */}
        <div className={classes.logo}>
          <Link to='/'>
            {/* <img src={logo} alt="LPT" /> */}
            Lande Patil Transport
          </Link>
        </div>
        </div>

        {loggedInUser && loggedInUser.username && <div className={classes.logout}>
          Date: {currentDate}<br />
          {user.employee && user.employee.name ? user.employee.name : user.username}&nbsp; <IconButton size='small' onClick={handleLogout} sx={{ color: '#b7c0cd', fontSize: '1em', '&:hover': { color: '#fff' } }}><LogoutIcon sx={{ fontSize: '1.5em' }} />&nbsp;Logout</IconButton>
        </div>}
        {(!loggedInUser || !loggedInUser.username) && <div className={classes.logout}>
          {/* Date: {currentDate}<br /> */}
          {user.employee && user.employee.name ? user.employee.name : user.username}&nbsp; <IconButton size='small' onClick={handleLogin} sx={{ color: '#b7c0cd', fontSize: '1em', '&:hover': { color: '#fff' } }}><LoginIcon sx={{ fontSize: '1.5em' }} />&nbsp;Login</IconButton>
        </div>}
      </div>
    </header>
  );
};

export default Header; 