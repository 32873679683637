import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import RequireAuth from "../lib/RequireAuth";

import Welcome from "../pages/Welcome";
import { useSelector } from "react-redux";

const UserList = lazy(() => import("../components/user/UsersList"));
const UserRegistration = lazy(() =>
  import("../components/user/UserRegistration")
);
const UserPermissions = lazy(() =>
  import("../components/user/UserPermissions")
);
const UserEdit = lazy(() => import("../components/user/UserEdit"));
const Articles = lazy(() =>
  import("../components/master/Articles/ArticlesList")
);
const ArticleAdd = lazy(() =>
  import("../components/master/Articles/ArticleAdd")
);
const ArticleEdit = lazy(() =>
  import("../components/master/Articles/ArticleEdit")
);
// const Reports = lazy(() => import("../pages/Reports"));
const Places = lazy(() => import("../components/master/Places/PlacesList"));
const PlaceAdd = lazy(() => import("../components/master/Places/PlaceAdd"));
const PlaceEdit = lazy(() => import("../components/master/Places/PlaceEdit"));
const BranchList = lazy(() =>
  import("../components/master/Branches/BranchList")
);
const BranchAdd = lazy(() => import("../components/master/Branches/BranchAdd"));
const BranchEdit = lazy(() =>
  import("../components/master/Branches/BranchEdit")
);
const Customers = lazy(() =>
  import("../components/master/Customers/CustomersList")
);
const CustomerAdd = lazy(() =>
  import("../components/master/Customers/CustomerAdd")
);
const CustomerEdit = lazy(() =>
  import("../components/master/Customers/CustomerEdit")
);
const Drivers = lazy(() => import("../components/master/Drivers/DriversList"));
const DriverAdd = lazy(() => import("../components/master/Drivers/DriverAdd"));
const DriverEdit = lazy(() =>
  import("../components/master/Drivers/DriverEdit")
);
const Employees = lazy(() =>
  import("../components/master/Employees/EmployeeList")
);
const EmployeeAdd = lazy(() =>
  import("../components/master/Employees/EmployeeAdd")
);
const EmployeeEdit = lazy(() =>
  import("../components/master/Employees/EmployeeEdit")
);
const Vehicles = lazy(() =>
  import("../components/master/Vehicles/VehiclesList")
);
const VehicleAdd = lazy(() =>
  import("../components/master/Vehicles/VehicleAdd")
);
const VehicleEdit = lazy(() =>
  import("../components/master/Vehicles/VehicleEdit")
);
const VehicleTypes = lazy(() =>
  import("../components/master/VehicleTypes/VehicleTypesList")
);
const VehicleTypeAdd = lazy(() =>
  import("../components/master/VehicleTypes/VehicleTypeAdd")
);
const VehicleTypeEdit = lazy(() =>
  import("../components/master/VehicleTypes/VehicleTypeEdit")
);
const Suppliers = lazy(() =>
  import("../components/master/Suppliers/SuppliersList")
);
const SupplierAdd = lazy(() =>
  import("../components/master/Suppliers/SupplierAdd")
);
const SupplierEdit = lazy(() =>
  import("../components/master/Suppliers/SupplierEdit")
);
const BankList = lazy(() => import("../components/master/BankList/BankList"));
const BankAdd = lazy(() => import("../components/master/BankList/BankAdd"));
const BankEdit = lazy(() => import("../components/master/BankList/BankEdit"));
const BankAccountList = lazy(() =>
  import("../components/master/BankAccounts/BankAccountList")
);
const BankAccountAdd = lazy(() =>
  import("../components/master/BankAccounts/BankAccountAdd")
);
const BankAccountEdit = lazy(() =>
  import("../components/master/BankAccounts/BankAccountEdit")
);

const LorryReceipts = lazy(() =>
  import("../components/transactions/LorryReceipts/LorryReceipts")
);
const LorryReceiptAdd = lazy(() =>
  import("../components/transactions/LorryReceipts/LorryReceiptAdd")
);
const LorryReceiptEdit = lazy(() =>
  import("../components/transactions/LorryReceipts/LorryReceiptEdit")
);

const InwardReceipts = lazy(() =>
  import("../components/transactions/InwardReceipts/InwardReceipts")
);
const InwardReceiptAdd = lazy(() =>
  import("../components/transactions/InwardReceipts/InwardReceiptAdd")
);
const InwardReceiptEdit = lazy(() =>
  import("../components/transactions/InwardReceipts/InwardReceiptEdit")
);

const BillList = lazy(() =>
  import("../components/transactions/BillList/BillList")
);
const BillAdd = lazy(() =>
  import("../components/transactions/BillList/BillAdd")
);
const BillEdit = lazy(() =>
  import("../components/transactions/BillList/BillEdit")
);

const LorryReceiptRegister = lazy(() =>
  import("../components/reports/LorryReceiptRegister/LorryReceiptRegister")
);

const BillRegister = lazy(() =>
  import("../components/reports/BillRegister/BillRegister")
);

const FuelRegister = lazy(() =>
  import("../components/reports/FuelRegister/FuelRegister")
);

const InwardRegister = lazy(() =>
  import("../components/reports/InwardRegister/InwardRegister")
);

const ReturnedLorryReceipts = lazy(() =>
  import("../components/transactions/ReturnedLorryReceipts/ReturnedLorryReceipts")
);
const ReturnedAddLorryReceipts = lazy(() =>
  import("../components/transactions/ReturnedLorryReceipts/ReturnedLorryReceiptAdd")
);
const ReturnedEditLorryReceipts = lazy(() =>
  import("../components/transactions/ReturnedLorryReceipts/ReturnedLorryReceiptEdit")
);


const DieselReceipts = lazy(() =>
  import("../components/transactions/DieselReceipts/DieselReceipts")
);
const DieselReceiptsAdd = lazy(() =>
  import("../components/transactions/DieselReceipts/DieselReceiptAdd")
);
const DieselReceiptsEdit = lazy(() =>
  import("../components/transactions/DieselReceipts/DieselReceiptEdit")
);

const Pumps = lazy(() => import("../components/master/FuelPump/PumpList"));
const PumpAdd = lazy(() => import("../components/master/FuelPump/PumpAdd"));
const PumpEdit = lazy(() => import("../components/master/FuelPump/PumpEdit"));

const Login = lazy(() => import("../components/user/Login"));

const Unauthorized = lazy(() => import("../pages/Unauthorized"));
const InwardPrint = lazy(() => import("../public/InwardPrint"));

const NotFound = lazy(() => import("../components/UI/NotFound"));
const CustomRoutes = () => {
  const user = useSelector(state => state.user);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        
        <Route path="/" element={user.username === '' ? <Login /> : <Welcome />} />
        <Route path="/users">
          <Route index element={<Navigate to="/users/usersList" />} />
          <Route>
            <Route path="usersList" element={<UserList />} />
            <Route path="userRegistration" element={<UserRegistration />} />
            <Route path="userEdit" element={<UserEdit />} />
            <Route path="userPermissions" element={<UserPermissions />} />
          </Route>
        </Route>
        <Route path="/master">
          {/* <Route index element={<RequireAuth parent='master' path='articles' process='read'><Navigate to='/master/articles' /></RequireAuth>} /> */}
          <Route
            index
            element={
              <RequireAuth parent="master" path="articles" process="read">
                <Navigate to="/master/places" />
              </RequireAuth>
            }
          />
          <Route path='articles'>
          <Route index element={<RequireAuth parent='master' path='articles' process='read'><Articles /></RequireAuth>} />
          <Route path='addArticle' element={<RequireAuth parent='master' path='articles' process='write'><ArticleAdd /></RequireAuth>} />
          <Route path='editArticle' element={<RequireAuth parent='master' path='articles' process='write'><ArticleEdit /></RequireAuth>} />
        </Route>
          <Route path="places">
            <Route
              index
              element={
                <RequireAuth parent="master" path="places" process="read">
                  <Places />
                </RequireAuth>
              }
            />
            <Route
              path="addPlace"
              element={
                <RequireAuth parent="master" path="places" process="write">
                  <PlaceAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editPlace"
              element={
                <RequireAuth parent="master" path="places" process="write">
                  <PlaceEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="branches">
            <Route
              index
              element={
                <RequireAuth parent="master" path="branches" process="read">
                  <BranchList />
                </RequireAuth>
              }
            />
            <Route
              path="addBranch"
              element={
                <RequireAuth parent="master" path="branches" process="write">
                  <BranchAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editBranch"
              element={
                <RequireAuth parent="master" path="branches" process="write">
                  <BranchEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="customers">
            <Route
              index
              element={
                <RequireAuth parent="master" path="customers" process="read">
                  <Customers />
                </RequireAuth>
              }
            />
            <Route
              path="addCustomer"
              element={
                <RequireAuth parent="master" path="customers" process="write">
                  <CustomerAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editCustomer"
              element={
                <RequireAuth parent="master" path="customers" process="write">
                  <CustomerEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="drivers">
            <Route
              index
              element={
                <RequireAuth parent="master" path="drivers" process="read">
                  <Drivers />
                </RequireAuth>
              }
            />
            <Route
              path="addDriver"
              element={
                <RequireAuth parent="master" path="drivers" process="write">
                  <DriverAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editDriver"
              element={
                <RequireAuth parent="master" path="drivers" process="write">
                  <DriverEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="employees">
            <Route
              index
              element={
                <RequireAuth parent="master" path="employees" process="read">
                  <Employees />
                </RequireAuth>
              }
            />
            <Route
              path="addEmployee"
              element={
                <RequireAuth parent="master" path="employees" process="write">
                  <EmployeeAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editEmployee"
              element={
                <RequireAuth parent="master" path="employees" process="write">
                  <EmployeeEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="vehicles">
            <Route
              index
              element={
                <RequireAuth parent="master" path="vehicles" process="read">
                  <Vehicles />
                </RequireAuth>
              }
            />
            <Route
              path="addVehicle"
              element={
                <RequireAuth parent="master" path="vehicles" process="write">
                  <VehicleAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editVehicle"
              element={
                <RequireAuth parent="master" path="vehicles" process="write">
                  <VehicleEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="vehicleTypes">
            <Route
              index
              element={
                <RequireAuth parent="master" path="vehicleTypes" process="read">
                  <VehicleTypes />
                </RequireAuth>
              }
            />
            <Route
              path="addVehicleType"
              element={
                <RequireAuth
                  parent="master"
                  path="vehicleTypes"
                  process="write"
                >
                  <VehicleTypeAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editVehicleType"
              element={
                <RequireAuth
                  parent="master"
                  path="vehicleTypes"
                  process="write"
                >
                  <VehicleTypeEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="suppliers">
            <Route
              index
              element={
                <RequireAuth parent="master" path="suppliers" process="read">
                  <Suppliers />
                </RequireAuth>
              }
            />
            <Route
              path="addSupplier"
              element={
                <RequireAuth parent="master" path="suppliers" process="write">
                  <SupplierAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editSupplier"
              element={
                <RequireAuth parent="master" path="suppliers" process="write">
                  <SupplierEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="banks">
            <Route
              index
              element={
                <RequireAuth parent="master" path="banks" process="read">
                  <BankList />
                </RequireAuth>
              }
            />
            <Route
              path="addBank"
              element={
                <RequireAuth parent="master" path="banks" process="write">
                  <BankAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editBank"
              element={
                <RequireAuth parent="master" path="banks" process="write">
                  <BankEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="bankAccounts">
            <Route
              index
              element={
                <RequireAuth parent="master" path="bankAccounts" process="read">
                  <BankAccountList />
                </RequireAuth>
              }
            />
            <Route
              path="addBankAccount"
              element={
                <RequireAuth
                  parent="master"
                  path="bankAccounts"
                  process="write"
                >
                  <BankAccountAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editBankAccount"
              element={
                <RequireAuth
                  parent="master"
                  path="bankAccounts"
                  process="write"
                >
                  <BankAccountEdit />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="pumps">
            <Route
              index
              element={
                <RequireAuth parent="master" path="pumps" process="read">
                  <Pumps />
                </RequireAuth>
              }
            />
            <Route
              path="addPump"
              element={
                <RequireAuth parent="master" path="pumps" process="write">
                  <PumpAdd />
                </RequireAuth>
              }
            />
            <Route
              path="editPump"
              element={
                <RequireAuth parent="master" path="pumps" process="write">
                  <PumpEdit />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
        <Route path="/transactions">
          <Route
            index
            element={<Navigate to="/transactions/lorryReceipts" />}
          />
          <Route path="lorryReceipts">
            <Route index element={<LorryReceipts />} />
            <Route path="addLorryReceipt" element={<LorryReceiptAdd />} />
            <Route path="editLorryReceipt" element={<LorryReceiptEdit />} />
          </Route>
          <Route path="inwardReceipts">
            <Route index element={<InwardReceipts />} />
            <Route path="addInwardReceipt" element={<InwardReceiptAdd />} />
            <Route path="editInwardReceipt" element={<InwardReceiptEdit />} />
          </Route>
          <Route path="returnLorryReceiptList">
            <Route index element={<ReturnedLorryReceipts />} />
            <Route path="returnedAddLorryReceipt" element={<ReturnedAddLorryReceipts />} />
            <Route path="returnedEditLorryReceipt" element={<ReturnedEditLorryReceipts />} />
          </Route>
                    
          <Route path="billList">
            <Route index element={<BillList />} />
            <Route path="addBill" element={<BillAdd />} />
            <Route path="editBill" element={<BillEdit />} />
          </Route>

          <Route path="dieselReceipts">
            <Route index element={<DieselReceipts />} />
            <Route path="addDiesel" element={<DieselReceiptsAdd />} />
            <Route path="editDiesel" element={<DieselReceiptsEdit />} />
          </Route>

          
        </Route>
        <Route path="/reports">
          <Route index element={<LorryReceiptRegister />} />
          <Route
            path="/reports/lorryReceiptRegister"
            element={<LorryReceiptRegister />}
          />          
          <Route path="/reports/billRegister" element={<BillRegister />} />
          <Route path="/reports/fuelRegister" element={<FuelRegister />} />
          <Route path="/reports/inwardRegister" element={<InwardRegister />} />
          
        </Route>
        {/* <Route path="/reports" element={<Reports />} /> */}
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export const PublicRoutes = () => {  
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/inwardPrint/:lrId" element={<InwardPrint />} />        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default CustomRoutes;
